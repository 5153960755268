.cart-page-title{
    font-family: 'Lobster', arial;
    text-align: center;
    margin-top: 5rem;
}

.cart-container{
    padding-inline: 1rem;
    margin-top:2.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cart-items{
    width: 60%;
    @media (max-width: 786px) {
        width: 100%;
    }
}

.cart-totals{
    width: 40%;
    @media (max-width: 786px) {
        margin-top: 1rem;
        width: 100%;
    }
}

.cart-background{
    position: absolute;
    bottom: -1rem;
    opacity: 100%;
    z-index: -1;
    
}