.testimonial-container{
    display: flex;
    flex-direction: column;
}

.testimonial-card {
    padding: 2rem;
    width: 13rem;
    box-shadow: 5.66px 5.66px 10px #707070;
    border-radius: 20px;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: space-between;
    margin-bottom: 1.2rem;
    // flex: 1;
    height: 22rem;

    @media screen and (max-width: 417px) {
        transform: scale(0.85);
    }
    @media screen and (max-width: 365px) {
        transform: scale(0.7);
    }
    .title {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1rem;
    
        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
    
    .text {
        text-align: center;
        padding: 0px;
        margin-bottom: 1rem;
    
        @media screen and (max-width: 768px) {
            font-size: 1.2rem;
        }
    }
    
    .image {
        border-radius: 50%;
        height: 8rem;
        width: 8rem;
        margin: 0 auto;
    
        // @media screen and (max-width: 768px) {
        //     max-height: 6rem;
        // }
    }
}

