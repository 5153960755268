$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.product-card {
    //max-width: 786px;
    //min-width: 300px;
    margin: 2rem;
    display: flex;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 2.5rem;

    .product-image {
        margin-block: 1rem;
        box-sizing: border-box;
        //border: 1px solid rgba(0,0,0,1);
        height: 30vh;
        margin-bottom: 20px;
        img {
            height: 120%;
            display: block;
        }
    }

    .product-details {
        //flex-grow: 1;
        padding: 1rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // max-width: 100%;

        .product-title {
            font-size: 1.5rem;
            text-align: center;
            max-width: 20rem;
            padding-block:0.5rem ;
        }

        .product-description{
            font-family: 'Open Sans', sans-serif;
            max-width: 20rem;
            text-align: center;
            padding-block: 0.5rem;
        }
       
        .product-price{
            font-family: 'Open Sans', sans-serif;
            font-size: 1.5rem;
            text-align: center;
            max-width: 20rem;
            padding-block:0.5rem ;
            font-weight: 600;
        }
        .product-options {
            margin-block: 1rem;
        }

        .add-to-cart {
            font-size: 1.2rem;
            margin-block: 0.25rem;
            background-color: $primary-color;
            color: white;
            border: none;
            border-radius: 20px;
            padding: 0.5rem 2rem;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: darken($primary-color, 10%);
            }
             &:disabled {
                opacity: 0.3;
            }
        }

        .buy-now {
            font-size: 1.2rem;
            margin-block: 0.25rem;
            background-color: $gold-color;
            color: white;
            border: none;
            border-radius: 20px;
            padding: 0.5rem 2rem;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: darken($gold-color, 10%);
            }
        }
    }
}