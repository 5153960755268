$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.cart-total {
  background-color: #F5F5F5;
  border-radius: 20px;
  padding: 20px;
  text-align: right;
  margin-inline: 2rem;
  .cart-total-heading {
    font-size: 1.5rem;
    margin-top: 0;
  }

  .cart-total-subtotal,
  .cart-total-shipping,
  .cart-total-tax,
  .cart-total-total {
    font-size: 1.2rem;
    margin: 5px 0;
    .cart-total-category {
      font-weight: bold;
    }
  }

  .cart-total-divider {
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }

  .cart-total-space {
    height: 20px;
  }

  .cart-total-button {
    background-color: $gold-color;
    color: $primary-color;
    font-size: 1.2rem;
    padding: 10px 20px;
    font-weight: 600;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease;
    &:hover {
      background-color: darken($gold-color, 10%);
    }
  }

  .cart-coupon-apply {
    background-color: $gold-color;
    color: $primary-color;
    font-size: 1rem;
    padding: 3px 10px;
    font-weight: 600;
    border: none;
    border-radius: 0;
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease;
    &:hover {
      background-color: darken($gold-color, 10%);
    }
  }

  .cart-coupon-input{
    font-size: 1rem;
    //height: 100%;
    width: 25%;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 3px 10px;
  }
  .cart-clear-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-clear-button {
    background-color: transparent;
    color: $primary-color;
    font-size: 1rem;
    
    font-weight: 600;
    //width: 100%;
    border: none;
    
    cursor: pointer;
    margin-top: 20px;
    transition: all 0.3s ease;
    &:hover {
      border-bottom: 1px solid #ccc;
    }
  }
}

.empty-cart-message{
  text-align: center;
  font-size: 1.5rem;
  opacity: 65%;
}