$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.question {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 1px solid $gold-color;
    height: 100%;
    
    &:hover {
      background-color: #f5f5f5;
    }
  
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      
      &__text {
        font-size: 18px;
        font-weight: bold;
        margin-left: 10px;
        color: $primary-color;
    }
  
      &__arrow {
        width: 10px;
        height: 10px;
        border-top: 2px solid $primary-color;
        border-right: 2px solid $primary-color;
        transform: rotate(45deg);
        transition: transform 0.3s ease;
  
        &.open {
          transform: rotate(225deg);
        }
      }
    }
  
    &__answer {
      background-color: #f5f5f5;
      width: 100%;
      margin-top: auto;
        p{
            padding: 1rem;
            color: $primary-color;
  
        }
    }
  }
  