$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.cart-item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 4rem;
    border: solid #cacaca;
    border-radius: 2px;
    border-width: 1px 0;
    // background-color: rgba(216, 216, 216, 0.199);
    @media (max-width: 768px) {
      justify-content: left;
      padding: 0;
      padding-block: .5rem;
    }
    button {
      background-color: transparent;
      color: black;
      font-size: 1.2rem;
      font-weight: 600;
      border: none;
      cursor: pointer;
      transition: ease 0.3s;
    }
    
    button:hover {
      transform: scale(1.05);
    }
    *{
      color: $primary-color;
    }
  }
  .cart-item-qty-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    margin-left: auto; /* ADD THIS LINE */

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
  .cart-item-qty-value {
    width: 2rem;
    padding: 0.5rem;
    margin: 0.2rem;
    text-align: center;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  .cart-item-thumbnail {
    max-width: 4rem;
    height: 4rem;
    padding: 0rem 3rem 0rem 0rem;
    object-fit: contain; /* set the value of object-fit */

  }
  .cart-item-title {
    font-size: 1.2rem;
  }
  .cart-item-price {
    font-size: 1rem;
  }
  .cart-details {
    padding-right: 2rem;
  }
  .cart-item-trash {
   
   padding-right: 1rem;
   color: black;
   transition: all 0.3s ease;
   &:hover{
    transform: scale(1.05);
   }
   @media (max-width: 768px) {
    // transform: scale(2);
    padding-left: 1rem;
  }
  }
  