$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);
$gold-color: rgba(250, 182, 59, 1);

.top-navbar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1000;
  background-color: transparent;
  color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.6rem;
  width: 100%;
  
  .nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    margin-right: 1rem;
    padding: 0;

    li {
      
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }

      a {
        font-size: 1.5rem;
        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
        font-family: 'Lobster';
        color: $primary-color;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        text-align: center;

        &:hover {
          color: rgba($gold-color, 1);
          text-shadow: 0 0 2px $primary-color;

        }
      }
    }
  }
}
