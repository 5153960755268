$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.difference{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .horseshoe{
        display: flex;
        justify-content: center;
        
        img{
            position: absolute;
            max-height: 10vh;
            transform: translate(0,-50%);
        }
        padding-bottom: 5vh;
    }
    
    .dif-content{
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .dif-title{
            font-family: 'Lobster', sans-serif;
            font-size: 2rem;
            text-align: center;
        }
    
        .dif-text{
            margin-top: 1.5rem;
            font-weight: 500;
            color: $primary-color;
            text-align: center;
            @media (min-width: 768px){
                max-width: 65%;
                font-size: 1.2rem;
                color: darken($primary-color, 30%);
            }
        }
    }
    
}

