$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.contact-us-container {
    background-color: white;
    padding: 3rem;
    max-width: 40vw;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 0 10px #707070;
    @media (max-width: 768px){
        max-width: 70vw;
    }

    &-title{
        font-family: 'Lobster', sans-serif;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1.2rem;
    }
    form {
      display: flex;
      flex-direction: column;
  
      div {
        margin-bottom: 1rem;
        
        label {
          display: block;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
  
        input, textarea {
          width: 100%;
          padding: 0.5rem;
          font-size: 1rem;
          border-radius: 4px;
          border: 1px solid #ccc;
        }
  
        textarea {
          height: 10rem;
        }
      }
  
      .submit-button {
        background-color: $gold-color;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
  
        &:hover {
          background-color: darken($gold-color, 10%);
        }
      }
    }
  }
  