$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.hero {
    position: relative;
    //max-width: 100vw;
    height: 100vh;
    height: 100dvh;

    background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)), url('../img/2.jpg') no-repeat center / cover;

    .container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        position: relative;

        .hero-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            @media (max-width: 768px){
                top: 0;
                left: 0;
                transform: scale(0.75);
                //transform: translate(0,0);
            }
            .hero-logo {
                max-height: 30vh;
                max-width: 100vw;
            }

            .hero-title {
                max-width: 100vw;

                font-size: 3rem;
                margin-bottom: 0.5rem;
                color: $gold-color;
                font-family: 'Lobster', sans-serif;
                text-shadow: 0 0 10px $primary-color;
            }

            .hero-subtitle {
                margin-top: 2rem;
                font-size: 1.3rem;
                color: whitesmoke;
                
            }
            .hero-button {
                background-color: $primary-color;
                color: $gold-color;
                font-size: 2rem;
                font-family: 'Lobster', sans-serif;
                border: 0.5px solid $gold-color;
                border-radius: 0;
                padding: 0.5rem 2rem;
                margin-top: 2rem;
                cursor: pointer;
                transition: background-color 0.3s ease-in-out;

                &:hover {
                    background-color: rgba($primary-color, 0.8);

                }

            }
        }

        
    }

    
    .custom-shape-divider-bottom-1680290870 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }
    
    .custom-shape-divider-bottom-1680290870 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        @media (max-width: 500px){
            width: calc(100% + 80px);
        }
        height: 80px;
    }
    
    .custom-shape-divider-bottom-1680290870 .shape-fill {
        fill: #FFFFFF;
    }

}