$primary-color: rgba(250, 182, 59, 1);
$secondary-color: rgba(0, 121, 112, 1);

.footer {
  //margin-top: 1.5rem;
  background-color: rgba(200, 200, 200, .5);
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  .footer-nav {
    
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-left: 1rem;

        &:first-child {
          margin-left: 0;
        }

        a {
          color: rgba(0, 0, 0, 0.5);
          text-decoration: none;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: rgba($secondary-color, 0.8);
          }
        }
      }
    }
  }

  

  .copyright {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    ul{
      display: none !important;
    }
  }
}
