$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

.checkout-response{
    
    border-radius: 20px;
    background-color: #F5F5F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 3rem;
    
    &-success{
        h1{
            color: green !important;
        }
    }
    &-failed{
        h1{
            color: red !important;
        }
    }
    h1{
        text-align: center;
        padding-bottom: 1.2rem;
    }
    h2,p{
        padding-bottom: 1.2rem;
        text-align: center;
    }
    button{
        background-color: $gold-color;
        color: $primary-color;
        font-size: 1.2rem;
        padding: 10px 20px;
        font-weight: 600;
        width: 100%;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;
        transition: all 0.3s ease;
        &:hover {
        background-color: darken($gold-color, 10%);
        }
    }
}