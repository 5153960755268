$gold-color: rgba(250, 182, 59, 1);
$primary-color: rgb(90, 59, 0);
$secondary-color: rgba(0, 121, 112, 1);

main{
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;
    margin-bottom: 1rem;
}
// .product-section{
//     background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)), url('../img/hero.webp') no-repeat center / cover;
//     padding: 2rem;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     max-width: 100vw;
//     @media (max-width: 768px) {
//         flex-direction: column;
//         margin-block: 0rem;
//     }
// }
// .product-section > * {
//     flex: 1 0 50%; /* This sets the width of the child elements to 50% of the available space each */
//     @media (min-width: 768px) {
//         margin: 0 1rem; /* Add some margin between the child elements */
//     }

//   }

.product-section {
    // background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)), url('../img/horses.jpg') no-repeat center / cover;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product-section>* {
    //width: calc(35% - 20px);
    margin-bottom: 20px;
}

/* square buttons */
.rec.rec-arrow {
    border-radius: 0;
    background-color: rgba($color: $primary-color, $alpha: 0.05);
    border: 1px solid $gold-color;
    box-shadow: none !important;
}

/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 50%;
    background-color: rgba($color: $primary-color, $alpha: 1) !important;
}

.rec.rec-arrow:focus {
    background-color: rgba($color: $primary-color, $alpha: 1) !important;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}

/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.rec.rec-dot:hover {
    box-shadow: rgba($primary-color, 0.5) 0px 0px 1px 3px;
}

.rec.rec-dot:focus {
    box-shadow: rgba($primary-color, 1) 0px 0px 1px 3px;
}

.rec.rec-dot_active {
    background-color: rgba($primary-color, 0.5);
    box-shadow: rgba($primary-color, 1) 0px 0px 1px 3px;
}

.testimonial-section {
    margin-block: 1.2rem;
}

.testimonial-carousel {
    //max-width: 60%;
    margin: 0 auto;

    // @media (max-width: 768px) {
    //     max-width: 85% !important;
    // }


}

.testimonial-title {
    font-family: 'Lobster', sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.2rem;
}
.faq-section{
    margin: 1.2rem auto;
    max-width: 98vw;
    @media (min-width: 768px){
        max-width: 60vw;
    }
}
.faq-title {
    font-family: 'Lobster', sans-serif;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.contact-section{
    margin-top: 2.5rem;
}