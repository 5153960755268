.banner-container{
    background-color: black;
    .banner-text{
        color: white;
        text-align: center;
        padding-block: 3px;
        @media  (max-width: 428px) {
            font-size: 0.7rem;
        }
    }
}